const showTagsNav = ref(false);
export function useTagsNav() {
  function toggle() {
    showTagsNav.value = !showTagsNav.value;
  }

  async function open() {
    //note: this is needed in order to solve a timing issue when user comes to album detail from notifications (or from a route that doesn't have user id on it)
    //this also solves a timing issue when loading the nav for the first time from the server and scrolling to selected element, fml
    await waitFor(1);
    showTagsNav.value = true;
  }

  function close() {
    showTagsNav.value = false;
  }

  return {
    showTagsNav,
    toggle,
    open,
    close
  }
}

const showAlbumsNav = ref(false);
export function useAlbumsNav() {
  function toggle() {
    showAlbumsNav.value = !showAlbumsNav.value;
  }

  async function open() {
    //note: this is needed in order to solve a timing issue when user comes to album detail from notifications (or from a route that doesn't have user id on it)
    //this also solves a timing issue when loading the nav for the first time from the server and scrolling to selected element, fml
    await waitFor(1);
    showAlbumsNav.value = true;
  }

  function close() {
    showAlbumsNav.value = false;
  }

  return {
    showAlbumsNav,
    toggle,
    open,
    close
  }
}

const showPeopleNav = ref(false);
export function usePeopleNav() {
  function toggle() {
    showPeopleNav.value = !showPeopleNav.value;
  }

  async function open() {
    //note: this is needed in order to solve a timing issue when user comes to album detail from notifications (or from a route that doesn't have user id on it)
    //this also solves a timing issue when loading the nav for the first time from the server and scrolling to selected element, fml
    await waitFor(1);
    showPeopleNav.value = true;
  }

  function close() {
    showPeopleNav.value = false;
  }

  return {
    showPeopleNav,
    toggle,
    open,
    close
  }
}

